<template>
  <customer-page>
    <template v-slot:page-headline>
      {{ pageHeadline }} <span v-if="contractPeriod">{{ contractPeriod.contractYear }}</span>
    </template>
    <template v-slot:page-content-container>

      <section class="content-block form pt-3 pb-5">
        <cart v-if="contractPeriod" ref="cart" :contract-period-id="contractPeriod.id" :contract-year="contractPeriod.contractYear" :show-kg-price="true" :view-only="true" :quantity-type="quantityType">
          <template #cart-modal="{params}">
            <zero-quantity-input-modal
                v-if="showZeroQuantityInputModal"
                :params="params"
                @closed="showZeroQuantityInputModal = false"
                @back-btn-clicked="$router.replace({ name: backUrlName, params: { contractPeriodId: this.contractPeriodId }, query: { keepCart: true }})"
                @submit-zero-quantity-input="onSubmitZeroQuantityInput"
            />
          </template>
        </cart>
      </section>
      <section class="content-block form pt-3 pb-5">
        <div class="container pt-3">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1 checkout-customer" v-if="!isLoading">
              <h4 class="my-4">{{ $t(`Unternehmen`) }}</h4>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="firma2" class="col-md-5 col-form-label">{{ $t(`Firma`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext" id="firma2" name="firma" :value="customer.company">
                </div>
              </div>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="lucid-nr3" class="col-md-5 col-form-label">{{ $t(`LUCID Nr.`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext" id="lucid-nr3" name="lucid-nr" :value="customer.lucidNumber">
                </div>
              </div>

              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="vat-id" class="col-md-5 col-form-label">{{ $t(`Umsatzsteuer ID-Nr.`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext" id="vat-id" name="vat-id" :value="customer.vatId">
                </div>
              </div>

              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="tax-number" class="col-md-5 col-form-label">{{ $t(`Steuer-Nr.`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext" id="tax-number" name="tax-number" :value="customer.taxNumber">
                </div>
              </div>

              <h4 class="my-4">{{ $t(`Rechnungsadresse`) }}</h4>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="firma24" class="col-md-5 col-form-label">{{ $t(`Firma`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext form-lg" id="firma24" name="firma" :value="customer.billingAddress.company">
                </div>
              </div>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="plz25" class="col-md-5 col-form-label">{{ $t(`PLZ / Ort`) }}</label>
                <div class="col-md-7">
                  <div class="d-flex">
                    <input type="text" readonly="" class="form-control-plaintext form-lg" id="plz25" name="plz" :value="customer.billingAddress.zipcode">
                      &nbsp;
                    <input type="text" readonly="" class="form-control-plaintext form-lg" id="ort26" name="ort" :value="customer.billingAddress.city">
                  </div>
                </div>
              </div>

              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="land27" class="col-md-5 col-form-label">{{ $t(`Land`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext form-lg" id="land27" name="land" :value="customer.billingAddress.country.name">
                </div>
              </div>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="strae-hausnr28" class="col-md-5 col-form-label">{{ $t(`Straße Haus-Nr`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext form-lg" id="strae-hausnr28" name="strae-hausnr" :value="customer.billingAddress.street">
                </div>
              </div>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="adresszeile-129" class="col-md-5 col-form-label">{{ $t(`Adresszeile 1`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext form--lg" id="adresszeile-129" name="adresszeile-1" :value="customer.billingAddress.additionalAddressLine1">
                </div>
              </div>
              <div class="form-group mb-3 row border-top border-lg-0">
                <label for="adresszeile-230" class="col-md-5 col-form-label">{{ $t(`Adresszeile 2`) }}</label>
                <div class="col-md-7">
                  <input type="text" readonly="" class="form-control-plaintext form-lg" id="adresszeile-230" name="adresszeile-2" :value="customer.billingAddress.additionalAddressLine2">
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 payment-method-container">
              <payment-method-select ref="paymentmethodSelect" :contract-period-id="contractPeriodId" v-model:payment-method-id="paymentMethodId" v-model:invoice-interval="invoiceInterval" @validity-change="onPaymentmethodValidityChange">
                <template v-slot:content-after="{ isLoading, hidePaymentMethods }">
                  <hr v-if="!isLoading && !hidePaymentMethods">
                </template>
              </payment-method-select>
              <gtc-container ref="gtcContainer" id="gtc-container" />
            </div>
          </div>
          <div class="col-12 col-lg-10 offset-lg-1 pt-5">
            <hr>
            <div class="w-100 d-flex justify-content-between pt-5">
              <div>
                <router-link class="btn btn-primary" :to="{ name: backUrlName, params: { contractPeriodId: this.contractPeriodId }, query: { keepCart: true }}">&lt; {{ $t(`zurück`) }}</router-link>
              </div>
              <div>
                <button class="btn btn-primary btn-checkout btn-lg" @click="onStartCheckout" :disabled="isProcessingCheckout">
                  <div class="spinner-border wizard-step4-loader" role="status" v-if="isProcessingCheckout">
                    <span class="sr-only"></span>
                  </div>
                  &gt; {{ $t(`kostenpflichtig bestellen`) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import Cart from "../../component/cart/cart";
import PaymentMethodSelect from "../../component/form/payment-method/payment-method-select";
import { Form, Field, ErrorMessage } from "vee-validate";
import GtcContainer from "../../component/form/checkout/gtc-container";
import Modal from "../../component/modal/modal.vue";
import ZeroQuantityInputModal from "../../component/modal/zero-quantity-input-modal.vue";

export default {
  name: "contract-period-checkout",
  components: {ZeroQuantityInputModal, GtcContainer, PaymentMethodSelect, Cart, CustomerPage, Form, Field, ErrorMessage},
  data() {
    return {
      isLoading: false,
      isProcessingCheckout: false,
      contractPeriod: null,
      customer: {},
      paymentMethodId: null,
      invoiceInterval: null,
      forceZeroSubmit: false,
      showZeroQuantityInputModal: false,
      paymentMethodValid: true
    }
  },
  created() {
    this.isLoading = true;
    this.forceZeroSubmit = false;
    easyLizeApp.apiClient.getContext((context) => {
      this.customer = context.customer;
      easyLizeApp.apiClient.getContractPeriod((contractPeriod) => {
        this.contractPeriod = contractPeriod;
        this.isLoading = false;
      }, this.contractPeriodId);
    });
  },
  computed: {
    pageHeadline() {
      if(this.quantityType == 'add') {
        return this.$t('Vertragserweiterung');
      }
      return this.$t('Jahresabschluss');
    },
    contractPeriodId() {
      return this.$route.params.contractPeriodId;
    },
    quantityType() {
      return this.$route.params.quantityType;
    },
    backUrlName() {
      if(this.quantityType === 'final') {
        return 'contract_period_final';
      }
      return 'contract_period_quantity_add';
    }
  },
  methods: {
    onPaymentmethodValidityChange(result) {
      this.paymentMethodValid = result.success;
    },
    onStartCheckout(e) {
      e.preventDefault();
      this.startCheckout();
    },
    onSubmitZeroQuantityInput() {
      this.showZeroQuantityInputModal = false;
      this.forceZeroSubmit = true;
      this.startCheckout();
    },
    startCheckout() {
      this.$refs.gtcContainer.validate().then((gtcResult) => {
        if (!gtcResult.valid) {
          return;
        }
        this.isProcessingCheckout = true;
        this.$refs.paymentmethodSelect.validate((paymentOptions) => {
          easyLizeApp.apiClient.createContractPeriodQuantity((response) => {
            if(response.success) {
              window.location.replace(response.redirect);
            } else if(response.errorCode == 'cart.zero-submit') {
              this.isProcessingCheckout = false;
              this.showZeroQuantityInputModal = true;
            } else {
              this.$router.push({
                name: 'contract_list',
                params: {
                  errorCode: response.errorCode || 'cart-empty'
                }
              });
            }
          }, this.contractPeriodId, this.quantityType, this.$refs.cart, this.paymentMethodId, this.invoiceInterval, paymentOptions, { forceZeroSubmit: this.forceZeroSubmit });
        });
      });
    }
  }
}
</script>
