<template>
  <Form ref="form">
    <h4 class="my-4">{{ $t(`Adresse`) }}</h4>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="plz16" class="col-md-5 col-form-label">{{ $t(`PLZ`) }}</label>
      <div class="col-md-7">
        <Field type="text" name="company-zipcode" class="form-control form-control-lg zip-validation" id="plz16" maxlength="16"
               v-model="customer.companyAddress.zipcode" :rules="isRequired" />
        <ErrorMessage name="company-zipcode" />
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="ort17" class="col-md-5 col-form-label">{{ $t(`Ort`) }}</label>
      <div class="col-md-7">
        <Field type="text" name="company-city" class="form-control form-control-lg city-validation" id="ort17" maxlength="70"
               v-model="customer.companyAddress.city" :rules="isRequired" />
        <ErrorMessage name="company-city" />
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="land18" class="col-md-5 col-form-label">{{ $t(`Land`) }}</label>
      <div class="col-md-7">
        <country-select name="company-countryId" v-model="customer.companyAddress.countryId" :rules="isRequired" ref="companyCountrySelect" @update:modelValue="onCompanyCountryChanged" />
        <ErrorMessage name="company-countryId" />
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="strae-hausnr19" class="col-md-5 col-form-label">{{ $t(`Straße Haus-Nr`) }}</label>
      <div class="col-md-7">
        <Field type="text" name="company-street" class="form-control form-control-lg street-validation" maxlength="80"
               id="strae-hausnr19" v-model="customer.companyAddress.street" :rules="isRequired" />
        <ErrorMessage name="company-street" />
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="adresszeile-120" class="col-md-5 col-form-label">{{ $t(`Adresszusatz`) }}</label>
      <div class="col-md-7">
        <Field type="text" name="company-additionalAddressLine1" class="form-control form-control-lg" maxlength="79"
               id="adresszeile-120"
               v-model="customer.companyAddress.additionalAddressLine1" />
      </div>
    </div>
    <hr class="my-4">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="true"
             v-model="customer.differentBillingAddress" id="differentBillingAddress" />
      <label class="form-check-label" for="differentBillingAddress">
        {{ $t(`separate Rechnungsadresse`) }}
      </label>
    </div>
    <div id="differentBillingAddressForm" v-if="customer.differentBillingAddress">
      <h4 class="my-4">{{ $t(`Rechnungsadresse`) }}</h4>
      <div class="form-group mb-3 row border-top border-lg-0">
        <label for="firma24" class="col-md-5 col-form-label">{{ $t(`Firma`) }}</label>
        <div class="col-md-7">
          <Field type="text" name="billing-company" class="form-control form-control-lg company-validation" maxlength="100"
                 id="firma24" v-model="customer.billingAddress.company" :rules="isRequired" />
          <ErrorMessage name="billing-company" />
        </div>
      </div>
      <div class="form-group mb-3 row border-top border-lg-0">
        <label for="plz25" class="col-md-5 col-form-label">{{ $t(`PLZ`) }}</label>
        <div class="col-md-7">
          <Field type="text" name="billing-zipcode" class="form-control form-control-lg zip-validation" id="plz25" maxlength="16"
                 v-model="customer.billingAddress.zipcode" :rules="isRequired" />
          <ErrorMessage name="billing-zipcode" />
        </div>
      </div>
      <div class="form-group mb-3 row border-top border-lg-0">
        <label for="ort26" class="col-md-5 col-form-label">{{ $t(`Ort`) }}</label>
        <div class="col-md-7">
          <Field type="text" name="billing-city" class="form-control form-control-lg city-validation" id="ort26" maxlength="70"
                 v-model="customer.billingAddress.city" :rules="isRequired" />
          <ErrorMessage name="billing-city" />
        </div>
      </div>
      <div class="form-group mb-3 row border-top border-lg-0">
        <label for="land27" class="col-md-5 col-form-label">{{ $t(`Land`) }}</label>
        <div class="col-md-7">
          <country-select name="billing-contryId" v-model="customer.billingAddress.countryId" :rules="isRequired" class="form-control form-control-lg" ref="billingCountrySelect" />
          <ErrorMessage name="billing-contryId" />
        </div>
      </div>
      <div class="form-group mb-3 row border-top border-lg-0">
        <label for="strae-hausnr28" class="col-md-5 col-form-label">{{ $t(`Straße Haus-Nr`) }}</label>
        <div class="col-md-7">
          <Field type="text" name="billing-street" class="form-control form-control-lg street-validation" maxlength="80"
                 v-model="customer.billingAddress.street" :rules="isRequired" />
          <ErrorMessage name="billing-street" />
        </div>
      </div>
      <div class="form-group mb-3 row border-top border-lg-0">
        <label for="adresszeile-129" class="col-md-5 col-form-label">{{ $t(`Adresszusatz`) }}</label>
        <div class="col-md-7">
          <Field type="text" name="billing-additionalAddressLine1" class="form-control form-control-lg" maxlength="79"
                 id="adresszeile-129"
                 v-model="customer.billingAddress.additionalAddressLine1" />
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import CountrySelect from "../country-select";

export default {
  emits: [ 'update:selectedCompanyCountry' ],
  name: "checkout-form-address",
  components: { CountrySelect, Form, Field, ErrorMessage },
  props: {
    customer: {
      type: Object,
      required: true
    },
    selectedCompanyCountry: {
      type: [Object, null],
      required: true
    }
  },
  methods: {
    isRequired(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    onCompanyCountryChanged(countryId) {
      this.$emit('update:selectedCompanyCountry', this.$refs.companyCountrySelect.getCountry(countryId));
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
}
</script>

<style scoped>

</style>